import {useState} from "react";
import {ExportTypeInterface} from "../../interfaces/ExportTypeInterface";
import {CollectionInterface} from "../../interfaces/CollectionInterface";
import {
    setAddCollectionProductsModal
} from "../../../modals/addCollectionProductsModal/AddCollectionProductsModalSlice";
import {useAppDispatch} from "../../../app/hooks";
import {BarLoader} from "react-spinners";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../../app/rootReducer";
import {StringTranslation} from "../../utils/StringTranslation";
import {exportCollections} from "../../utils/Export";

interface Props {
    collection: CollectionInterface;
    exportTypes: ExportTypeInterface[];
}


export const ExportBar = (props: Props) => {

    const dispatch = useAppDispatch();

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: translations} = useGetTranslationsQuery()
    const {collection, exportTypes} = props

    const [loading, setLoading] = useState<boolean>(false)
    const [active, setActive] = useState(false);
    const [selectedExportType, setSelectedExportType] = useState<ExportTypeInterface | null>(null);

    const paramObject = {
        collection: collection,
        selectedExportType: selectedExportType,
        title: StringTranslation('export-collections', translations, activeLanguage)
    }

    return (
        <div className="c-export-bar" key={collection.id}>

            <div className="c-export-bar__container">

                <div className="c-export-bar__container__left">

                    <button className="c-btn c-btn--primary c-export-bar__add-product-button" onClick={() => {
                        dispatch(setAddCollectionProductsModal({
                            showPopup: true,
                            collectionId: collection.id
                        }))
                    }}>
                        <span>+ {StringTranslation('add-product', translations, activeLanguage).toLowerCase()}</span>
                    </button>
                </div>

                <span
                    className="c-export-bar__container__title">{StringTranslation('download-collection-as', translations, activeLanguage)}</span>

                <div className="c-export-bar__container__right">
                    <div className="c-export-bar__select-options">


                        <div className={"c-select" + (active ? " is-active" : "")}>

                            <button className="c-select__btn" onClick={() => setActive(!active)}>

                                {selectedExportType ? (
                                    <div className="c-select__btn__selected c-select__btn__selected--option">
                                        <span>{selectedExportType.label}</span>
                                        <div className="tag">
                                            <span
                                                className={selectedExportType.color}>{selectedExportType.fileType}</span>
                                            <img className="img-arrow" src="/images/arrow-black.svg" alt=""/>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="c-select__btn__selected c-select__btn__selected--placeholder">
                                        <span>{StringTranslation('select-option', translations, activeLanguage)}...</span>
                                        <img className="img-arrow" src="/images/arrow-black.svg" alt=""/>
                                    </div>
                                )}

                            </button>

                            <div className="c-select__container">
                                <ul>
                                    {!!selectedExportType &&
                                        <li className="c-select__container__option c-select__container__option--placeholder"
                                            onClick={() => {
                                                setSelectedExportType(null)
                                                setActive(!active)
                                            }}>
                                            <span>{StringTranslation('select-option', translations, activeLanguage)}...</span>
                                        </li>
                                    }
                                    {exportTypes.filter((type) => type.id !== selectedExportType?.id).map((type: ExportTypeInterface, index: number) => (
                                        <li key={index} className="c-select__container__option" onClick={() => {
                                            setSelectedExportType(type)
                                            setActive(!active)
                                        }}>
                                            <span>{type.label}</span><span
                                            className={"tag " + type.color}>{type.fileType}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>

                    </div>
                    <button className="c-btn c-btn--primary c-export-bar__download-button" disabled={loading || !selectedExportType}
                            onClick={() => {
                                setLoading(true)
                                exportCollections(paramObject).then(() => {
                                    setLoading(false)
                                })
                            }}>
                        {!loading ? (
                            <>
                                <span>{StringTranslation('download', translations, activeLanguage)}</span>
                                <img src="/images/download.svg" alt="download"/>
                            </>
                        ) : (
                            <div className={'c-loader'}>
                                <BarLoader color={'#000000'}/>
                            </div>
                        )}
                    </button>
                </div>

            </div>

        </div>
    )
}

