import {ExportTypeInterface} from "../../interfaces/ExportTypeInterface";
import {useState} from "react";
import {StringTranslation} from "../../utils/StringTranslation";
import {LanguageInterface} from "../../interfaces/LanguageInterface";
import {useAppSelector} from "../../../app/hooks";
import {exportProductSelection} from "../../utils/Export";

interface Props {
    exportTypes: ExportTypeInterface[]
    translations: any
    activeLanguage: LanguageInterface | null
}

export const SelectedProductsExportBar = (props: Props) => {

    const {exportTypes, translations, activeLanguage} = props

    const [loading, setLoading] = useState<boolean>(false)
    const [active, setActive] = useState(false);
    const [selectedExportType, setSelectedExportType] = useState<ExportTypeInterface | null>(null);

    const {
        selectedProducts,
    } = useAppSelector(state => state.productRange);

    const paramObject = {
        products: selectedProducts,
        selectedExportType: selectedExportType,
        title: StringTranslation('export-product-selection', translations, activeLanguage)
    }

    return (
        <>

            <span>{StringTranslation('download-selection-as', translations, activeLanguage)}</span>
            <div className="c-products-selected__selects">
                <div className={"c-select" + (active ? " is-active" : "")}>

                    <button className="c-select__btn" onClick={() => setActive(!active)}>

                        {selectedExportType ? (
                            <div
                                className="c-select__btn__selected c-select__btn__selected--option">
                                <span>{selectedExportType.label}</span>
                                <div className="tag">
                                            <span
                                                className={selectedExportType.color}>{selectedExportType.fileType}</span>
                                    <img className="img-arrow" src="/images/arrow-black.svg"
                                         alt=""/>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="c-select__btn__selected c-select__btn__selected--placeholder">
                                <span>{StringTranslation('select-option', translations, activeLanguage)}...</span>
                                <img className="img-arrow" src="/images/arrow-black.svg"
                                     alt=""/>
                            </div>
                        )}

                    </button>

                    <div className="c-select__container">
                        <ul>
                            {!!selectedExportType &&
                                <li className="c-select__container__option c-select__container__option--placeholder"
                                    onClick={() => {
                                        setSelectedExportType(null)
                                        setActive(!active)
                                    }}>
                                    <span>{StringTranslation('select-option', translations, activeLanguage)}...</span>
                                </li>
                            }
                            {exportTypes.filter((type) => type.id !== selectedExportType?.id).map((type: ExportTypeInterface, index: number) => (
                                <li key={index} className="c-select__container__option"
                                    onClick={() => {
                                        setSelectedExportType(type)
                                        setActive(!active)
                                    }}>
                                    <span>{type.label}</span><span
                                    className={"tag " + type.color}>{type.fileType}</span>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>

            <button className="c-btn c-btn--primary" disabled={loading}
                    onClick={() => {
                        setLoading(true)
                        exportProductSelection(paramObject).then(() => {
                            setLoading(false)
                        })
                    }}>
                <img src="/images/download.svg" alt="download"/>
            </button>

        </>
    )

}
